.img {
  display: inline-flex;
  height: 40px;
  margin: 1rem;
}

p {
  font-size: small;
}

.imgx {
  display: inline-flex;
  height: 30px;
  margin: 0.5rem;
}